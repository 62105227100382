import React, { useCallback } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import {
  type SiteDetailsQuery,
  useDisconnectGallagherMutation,
  useToggleGallagherIntegrationLicenseMutation,
} from "../../../../generated/admin";
import { GallagherConnectForm } from "./GallagherConnectForm";
import {
  EqDangerMessage,
  EqMessageError,
  EqMessageSuccess,
} from "../../../message/EqMessage";
import dayjs from "dayjs";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

export const GallagherConnect: React.FC<Props> = ({ dest }) => {
  const connectInfo = dest.integrations?.gallagher.info;
  const isLicensed = dest.integrations?.gallagher.license?.active;
  const [mutation, { loading }] = useDisconnectGallagherMutation({
    variables: { site: dest.uuid },
    refetchQueries: ["SiteDetails"],
  });
  const [toggleLicenseMutation, { loading: toggleLicenseLoading }] =
    useToggleGallagherIntegrationLicenseMutation({
      refetchQueries: ["SiteDetails"],
    });

  const disconnect = useCallback(async () => {
    const confirm = await EqDangerMessage({
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Disconnect",
      title: "Are you sure?",
      html: "Once disconnected, you cannot retrieve this record.",
    });
    if (confirm.value !== true || loading) {
      return;
    }

    try {
      const result = await mutation();
      if (result.data?.gallagher?.disconnect == null) {
        throw new Error("Could not disconnect.");
      }

      if (!result.data.gallagher.disconnect.success) {
        throw new Error(
          result.data.gallagher.disconnect.message ??
            "Unknown error response from server."
        );
      }

      EqMessageSuccess({ text: "Successfully disconnected from Gallagher." });
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [mutation, loading]);

  const toggleLicense = useCallback(
    async (enable: boolean) => {
      try {
        const result = await toggleLicenseMutation({
          variables: {
            site: dest.uuid,
            enable,
          },
        });

        if (
          result.data?.gallagher?.updateIntegrationLicense.__typename ===
          "GallagherAuthenticationFailure"
        ) {
          throw new Error(
            result.data.gallagher.updateIntegrationLicense.reason
          );
        }

        if (result.errors != null) {
          throw new Error(result.errors.map((e) => e.message).join(", "));
        }

        const toggled = enable ? "Enabled" : "Disabled";
        EqMessageSuccess({
          text: `${toggled} Equiem Integration Licence.`,
        });
      } catch (e) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [toggleLicenseMutation, dest.uuid]
  );

  return (
    <div className="gallagher pt-2">
      <h6>Connection</h6>
      <div className="border p-2 p-md-4 mb-2">
        {connectInfo == null ? (
          <GallagherConnectForm dest={dest} />
        ) : (
          <div className="d-flex justify-content-between">
            <div>
              <div>
                Account connected{" "}
                {dayjs(connectInfo.connectedTimestamp).fromNow()}. Last
                connected to API{" "}
                {dayjs(connectInfo.lastSuccessfulConnection).fromNow()}. API
                version: {connectInfo.version}
              </div>
              {isLicensed === false ? (
                <Form.Text className="text-muted">
                  <FaInfoCircle
                    color="orange"
                    className="mr-1"
                    style={{ marginTop: "-4px" }}
                  />
                  There is an issue with your Gallagher licence. You can
                  {connectInfo.useIntegrationLicense ? " disable " : " enable "}
                  the Equiem Integration Licence to try and resolve this issue.
                  If it persists, contact your Gallagher representative for
                  further assistance.
                </Form.Text>
              ) : null}
              <Form.Check
                id="gallagherUseIntegrationLicense"
                label={
                  <>
                    Enable Equiem Integration Licence
                    {toggleLicenseLoading ? (
                      <Spinner size="sm" animation="grow" className="ml-1" />
                    ) : null}
                  </>
                }
                className="mt-2"
                type="switch"
                onChange={(e) => toggleLicense(e.target.checked)}
                checked={connectInfo.useIntegrationLicense}
                disabled={toggleLicenseLoading}
              />
            </div>
            <Button
              name="gallagherDisconnect"
              variant="danger"
              size="sm"
              className="align-self-center"
              onClick={disconnect}
              disabled={loading}
            >
              {loading ? (
                <span>
                  <Spinner size="sm" animation="grow" /> Loading...
                </span>
              ) : (
                "Disconnect"
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
