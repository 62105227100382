import React from "react";
import { Logo } from "./svgs/Logo";

export const LoginPage = ({
  greeting,
  loginWithRedirect
}: {
  greeting: string;
  loginWithRedirect: () => void;
}) => {
  return (
    <div className={"vw-100 vh-100 position-relative bg-secondary"}>
      <header className="position-absolute p-3">
        <Logo />
      </header>
      <main className="container min-h-100 d-flex flex-column">
        <div className="vh-100 row align-content-center justify-content-center">
          <div className="col-sm-8 col-md-7 col-lg-5 col-xl-4 text-white-90 text-center mt-n4">
            <h2 className="text-primary font-weight-light mb-4">{greeting}</h2>
            <button
              onClick={loginWithRedirect}
              className="btn btn-outline-primary px-5 shadow-none"
              type="button"
            >
              Sign in
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};
