import React from "react";
import { IntegrationConfiguration } from "./IntegrationConfiguration";
import { useAc1Data } from "./useAc1Data";

interface Props {
  siteUuid: string;
}

export const IntegrationConfigurationList: React.FC<Props> = ({ siteUuid }) => {
  const { integrations } = useAc1Data(siteUuid);

  if (integrations.length === 0) {
    return null;
  }

  return (
    <div className="container-main">
      {integrations.length > 0 ? (
        <h5>Existing Integration Configurations</h5>
      ) : null}
      {integrations.map((integration) => (
        <IntegrationConfiguration
          key={integration.uuid}
          integration={integration}
          siteUuid={siteUuid}
        />
      ))}
    </div>
  );
};
