import { UserFormData } from "../model/UserFormData";
import {
  ProfileDetailsQuery,
  UpdateUserProfileInput,
  UpdateUserMutationFn,
  ProfileRegistrationType,
  AdminRegistrationType,
  AssignUserToApartmentMutationFn,
  ProfileStatus
} from "../../../../generated/admin";
import {
  EqMessageSuccess,
  EqMessageError,
  MessageFn
} from "../../../message/EqMessage";
import { useUpdatedUserStore } from "../user-store/UpdatedUserStore";
import { useMemo } from "react";
import { useConfig } from "../../../../providers/ConfigProvider";
import { stringNotEmpty } from "../../../../util/stringNotEmpty";

const convertType = (
  profile?: ProfileRegistrationType
): AdminRegistrationType => {
  if (profile == null) {
    return AdminRegistrationType.Commercial;
  }

  switch (profile) {
    case ProfileRegistrationType.Commercial:
      return AdminRegistrationType.Commercial;
    case ProfileRegistrationType.Visitor:
      return AdminRegistrationType.Visitor;
    case ProfileRegistrationType.Residential:
      return AdminRegistrationType.Residential;
  }
};

/**
 * Hooks for user form submit.
 */
export function useUserFormSubmit(
  user: ProfileDetailsQuery["user"],
  mutation: UpdateUserMutationFn,
  apartmentMutation: AssignUserToApartmentMutationFn,
  finishCallback?: () => void,
  successMsgFn: MessageFn = EqMessageSuccess,
  errorMsgFn: MessageFn = EqMessageError
) {
  const store = useUpdatedUserStore();
  const config = useConfig();
  return useMemo(
    () => ({
      onSubmit: async (input: UserFormData) => {
        let siteProfileV2: UpdateUserProfileInput["siteProfileV2"] = undefined;

        if (input.activeSite?.value != null && input.activeSite.value !== "") {
          const currentSiteProfile = user?.profile?.siteProfiles.edges.find(
            (e) => {
              return e.node?.site.uuid === input.activeSite!.value;
            }
          );

          siteProfileV2 = {
            profileUUID: user?.uuid ?? "",
            siteUUID: input.activeSite.value,
            subscribedToEmails:
              input.emailSubscription ??
              currentSiteProfile?.node?.subscribedToEmails ??
              false,
            subscribedToNotifications:
              input.notificationSubscription ??
              currentSiteProfile?.node?.subscribedToNotifications ??
              false,
            registrationType: input.registrationType?.value
              ? (input.registrationType?.value as AdminRegistrationType)
              : convertType(currentSiteProfile?.node?.registrationType)
          };

          if (input.fields != null) {
            siteProfileV2.fields = Object.keys(input.fields).map((key) => {
              return {
                key,
                value: input.fields![key]
              };
            });
          }

          if (input.attributes === null) {
            siteProfileV2.siteAttributes = [];
          } else if (input.attributes != null) {
            siteProfileV2.siteAttributes = input.attributes.map((a) => {
              return a.value;
            });
          }

          if (input.groups === null) {
            siteProfileV2.siteGroups = [];
          } else if (input.groups != null) {
            siteProfileV2.siteGroups = input.groups.map((a) => {
              return a.value;
            });
          }
        }

        try {
          const uuid = user?.uuid as string;
          const selectedSites =
            (input.sites != null && typeof input.sites !== 'boolean')
              ? (typeof input.sites == 'string' ? [{ portalUuid: input.sites }] : input.sites.map((portalUuid) => ({ portalUuid })))
              : undefined;

          const existingSites =
            user?.destinationsV2?.edges.flatMap((d) => d.node != null ? [{ portalUuid: d.node.destination.uuid as string }] : []) ?? [];
          const sites = selectedSites?.concat(stringNotEmpty(input.siteSearchKeyword) ? existingSites : []);

          const result = await mutation({
            variables: {
              installation: config.installation,
              input: {
                profile: {
                  uuid,
                  firstName: input.firstName,
                  lastName: input.lastName,
                  email: input.email,
                  public: user?.profile?.public ?? false,
                  status: input.status.value,
                  avatar: input.avatar ?? undefined,
                  company: input.company?.value ?? undefined,
                  jobTitle:
                    input.position && input.position !== ""
                      ? input.position
                      : undefined,
                  mobileNumber:
                    input.mobile && input.mobile !== ""
                      ? input.mobile
                      : undefined,
                  siteProfileV2: siteProfileV2,
                  newWorldPermissions:
                    input.newWorldPermissions &&
                      input.newWorldPermissions.length > 0
                      ? input.newWorldPermissions
                      : []
                },
                sites
              }
            }
          });

          if (result.data == null) {
            throw new Error("Failed saving profile.");
          }

          if (result.data.updateUser.__typename === "UpdateUserFailure") {
            throw new Error(result.data.updateUser.reason);
          }

          if (input.apartment && input.activeSite) {
            await apartmentMutation({
              variables: {
                input: {
                  apartmentUuid: input.apartment.value,
                  destinationUuid: input.activeSite.value,
                  userUuid: uuid,
                  overrideExistingUser: false
                }
              }
            });
          }

          store.updateUser(
            uuid,
            (input.status.value as unknown) as ProfileStatus
          );

          successMsgFn({
            text: `Profile edited successfully.`
          });

          finishCallback && finishCallback();
        } catch (e) {
          console.log(e);
          errorMsgFn({
            text:
              e instanceof Error
                ? e.message
                : "Something went wrong, please contact admin."
          });
        }
      }
    }),
    [
      store,
      errorMsgFn,
      successMsgFn,
      finishCallback,
      mutation,
      apartmentMutation,
      user,
      config.installation
    ]
  );
}
