import React from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Select, { StylesConfig } from "react-select";
import { FormikContextType, useFormikContext } from "formik";
import { WhitelabelFormData } from "./WhitelabelFormData";
import { ToggleContainer } from "../../../components/ToggleContainer";
import startCase from "lodash/startCase";
import { 
    AppleStoreStatus,
    GoogleStoreStatus,
    WhitelabelStatus
} from "../../../generated/admin";
import { FaExternalLinkAlt } from "react-icons/fa";
import { stringNotEmpty } from "../../../util/stringNotEmpty";
import { EquiemOwner } from "./components/EquiemOwner";

interface Props {
    methods: FormikContextType<WhitelabelFormData>;
}

interface WhitelabelAppleStoreStatusOption {
    value: string;
    label: string;
  }

  interface WhitelabelGoogleStoreStatusOption {
    value: string;
    label: string;
  }

interface WhitelabelStatusOption {
    value: string;
    label: string;
    isDisabled: boolean;
  }

const appleStoreStatusOptions: WhitelabelAppleStoreStatusOption[] = [
    {
        value: AppleStoreStatus.Live,
        label: startCase(AppleStoreStatus.Live.toLowerCase()),
    },
    {
        value: AppleStoreStatus.MembershipExpires,
        label: startCase(AppleStoreStatus.MembershipExpires.toLowerCase()),
    },
    {
        value: AppleStoreStatus.MembershipExpired,
        label: startCase(AppleStoreStatus.MembershipExpired.toLowerCase()),
    },
    {
        value: AppleStoreStatus.PendingAgreement,
        label: startCase(AppleStoreStatus.PendingAgreement.toLowerCase()),
    },
    {
        value: AppleStoreStatus.PendingRelease,
        label: startCase(AppleStoreStatus.PendingRelease.toLowerCase()),
    },
    {
        value: AppleStoreStatus.StoreRejected,
        label: startCase(AppleStoreStatus.StoreRejected.toLowerCase()),
    },
    {
        value: AppleStoreStatus.WaitingForReview,
        label: startCase(AppleStoreStatus.WaitingForReview.toLowerCase()),
    },
];

const getAppleStoreStatusColor = (status: string) => {
    if (status === AppleStoreStatus.Live) {
        return "var(--lime)";
    }
    if (status === AppleStoreStatus.PendingRelease || 
        status === AppleStoreStatus.WaitingForReview || 
        status === AppleStoreStatus.MembershipExpires
    ) {
        return "var(--body)";
    }
    return "var(--deactivated)";
};

const appleStoreStatusStyles: StylesConfig<WhitelabelAppleStoreStatusOption> = {
    option: (styles, { data, isSelected, isFocused }) => {
        return {
            ...styles,
            color: getAppleStoreStatusColor(data.value),
            fontWeight: 400,
            backgroundColor: isSelected ? "#ddd" : isFocused ? "#f2f2f2" : undefined,
        };
    },
    singleValue: (styles, { data }) => {
        return { ...styles, color: getAppleStoreStatusColor(data.value), fontWeight: 400 };
    },
};

const googleStoreStatusOptions: WhitelabelGoogleStoreStatusOption[] = [
    {
        value: GoogleStoreStatus.AccountNotVerified,
        label: startCase(GoogleStoreStatus.AccountNotVerified.toLowerCase()),
    },
    {
        value: GoogleStoreStatus.Live,
        label: startCase(GoogleStoreStatus.Live.toLowerCase()),
    },
    {
        value: GoogleStoreStatus.PendingRelease,
        label: startCase(GoogleStoreStatus.PendingRelease.toLowerCase()),
    },
    {
        value: GoogleStoreStatus.StoreRejected,
        label: startCase(GoogleStoreStatus.StoreRejected.toLowerCase()),
    },
    {
        value: GoogleStoreStatus.WaitingForReview,
        label: startCase(GoogleStoreStatus.WaitingForReview.toLowerCase()),
    },
];

const getGoogleStoreStatusColor = (status: string) => {
    if (status === GoogleStoreStatus.Live) {
        return "var(--lime)";
    } else if (status === GoogleStoreStatus.StoreRejected || status === GoogleStoreStatus.AccountNotVerified) {
        return "var(--deactivated)";
    }
    return "var(--body)";
};

const googleStoreStatusStyles: StylesConfig<WhitelabelGoogleStoreStatusOption> = {
    option: (styles, { data, isSelected, isFocused }) => {
        return {
            ...styles,
            color: getGoogleStoreStatusColor(data.value),
            fontWeight: 400,
            backgroundColor: isSelected ? "#ddd" : isFocused ? "#f2f2f2" : undefined,
        };
    },
    singleValue: (styles, { data }) => {
        return { ...styles, color: getGoogleStoreStatusColor(data.value), fontWeight: 400 };
    },
};

const statusOptions: WhitelabelStatusOption[] = [
    {
        value: WhitelabelStatus.Draft,
        label: startCase(WhitelabelStatus.Draft.toLowerCase()),
        isDisabled: false
    },
    {
        value: WhitelabelStatus.ReadyForDevelopment,
        label:startCase(WhitelabelStatus.ReadyForDevelopment.toLowerCase()),
        isDisabled: false
    },
    {
        value: WhitelabelStatus.Development,
        label: startCase(WhitelabelStatus.Development.toLowerCase()),
        isDisabled: false
    },
    {
        value: WhitelabelStatus.DevelopmentBlocked,
        label: startCase(WhitelabelStatus.DevelopmentBlocked.toLowerCase()),
        isDisabled: false
    },
    {
        value: WhitelabelStatus.Developed,
        label: startCase(WhitelabelStatus.Developed.toLowerCase()),
        isDisabled: false
    },
    {
        value: WhitelabelStatus.AwaitingApproval,
        label: startCase(WhitelabelStatus.AwaitingApproval.toLowerCase()),
        isDisabled: false
    },
    {
        value: WhitelabelStatus.Live,
        label: startCase(WhitelabelStatus.Live.toLowerCase()),
        isDisabled: false
    },
    {
        value: WhitelabelStatus.Retired,
        label: startCase(WhitelabelStatus.Retired.toLowerCase()),
        isDisabled: false
    },
];

const getWhitelabelStatusColor = (status: string) => {
    if (status === WhitelabelStatus.DevelopmentBlocked || status === WhitelabelStatus.Retired) {
        return "var(--deactivated)";
    }
    if (status === WhitelabelStatus.Live) {
        return "var(--lime)";
    }
    return "var(--body)";
};

const styles: StylesConfig<WhitelabelStatusOption> = {
    option: (styles, { data, isSelected, isFocused }) => {
        return {
            ...styles,
            color: getWhitelabelStatusColor(data.value),
            fontWeight: 400,
            backgroundColor: isSelected ? "#ddd" : isFocused ? "#f2f2f2" : undefined,
        };
    },
    singleValue: (styles, { data }) => {
        return { ...styles, color: getWhitelabelStatusColor(data.value), fontWeight: 400 };
    },
};

const disableStatusOptions = (status: WhitelabelStatus) => {
    statusOptions.forEach((option) => {
        switch (option.value) {
            case WhitelabelStatus.Draft:
              option.isDisabled = status !== WhitelabelStatus.Draft;
              break;
            case WhitelabelStatus.ReadyForDevelopment:
              option.isDisabled = status !== WhitelabelStatus.Draft && 
                status !== WhitelabelStatus.ReadyForDevelopment;
              break;
            case WhitelabelStatus.Development:
              option.isDisabled = status === WhitelabelStatus.Live ||
                status === WhitelabelStatus.Retired;
              break;
            case WhitelabelStatus.DevelopmentBlocked:
              option.isDisabled = status === WhitelabelStatus.Live ||
                status === WhitelabelStatus.Retired;
              break;
            case WhitelabelStatus.Developed:
              option.isDisabled = status === WhitelabelStatus.Live ||
                status === WhitelabelStatus.Retired;
              break;
            case WhitelabelStatus.AwaitingApproval:
              option.isDisabled = status === WhitelabelStatus.Live ||
                status === WhitelabelStatus.Retired;
              break;
        }
    });
}

export const WhitelabelGeneralView: React.FC<Props> = ({ methods }) => {
    const fm = useFormikContext<WhitelabelFormData>();
    const show = fm.values.status !== WhitelabelStatus.Draft;

        const showJiraUrl = stringNotEmpty(methods.values.jiraIssueUrl);
        const jiraTicketNumber = methods.values.jiraIssueUrl?.split("/").pop();
        const appleStoreStatusValue = appleStoreStatusOptions.find((c) => c.value === methods.values.appleStoreStatus);
        const googleStoreStatusValue = googleStoreStatusOptions.find((c) => c.value === methods.values.googleStoreStatus);
        const statusValue = statusOptions.find((c) => c.value === methods.values.status);
        const expanded = fm.values.status !== WhitelabelStatus.Live && fm.values.status !== WhitelabelStatus.Retired;
        disableStatusOptions(methods.values.status);
        return (
            <ToggleContainer className="lg" title="Operational Details" show={expanded}>
                { show ?
                    <Form.Group as={Row} className="status-select" controlId="status">
                        <Form.Label column md="3">Status</Form.Label>
                        <Col md="9" lg="6">
                            <Select<WhitelabelStatusOption>
                                classNamePrefix="eq"
                                name="status-select"
                                aria-label="status-select"
                                styles={styles}
                                options={statusOptions}
                                value={statusValue}
                                onChange={(newValue) => {
                                    methods.setFieldValue(
                                        "status",
                                        newValue?.value
                                    );
                                }}
                            />
                        </Col>
                    </Form.Group>
                    : null
                }
                { show ?
                    <Form.Group as={Row} className="appleStoreStatus-select" controlId="appleStoreStatus">
                        <Form.Label column md="3">Apple Store Status</Form.Label>
                        <Col md="9" lg="6">
                            <Select<WhitelabelAppleStoreStatusOption>
                                classNamePrefix="eq"
                                name="appleStoreStatus-select"
                                aria-label="appleStoreStatus-select"
                                styles={appleStoreStatusStyles}
                                options={appleStoreStatusOptions}
                                value={appleStoreStatusValue}
                                onChange={(newValue) => {
                                    methods.setFieldValue(
                                        "appleStoreStatus",
                                        newValue?.value
                                    );
                                }}
                            />
                        </Col>
                    </Form.Group>
                    : null
                }
                { show ?
                    <Form.Group as={Row} className="googleStoreStatus-select" controlId="googleStoreStatus">
                        <Form.Label column md="3">Google Store Status</Form.Label>
                        <Col md="9" lg="6">
                            <Select<WhitelabelGoogleStoreStatusOption>
                                classNamePrefix="eq"
                                name="googleStoreStatus-select"
                                aria-label="googleStoreStatus-select"
                                styles={googleStoreStatusStyles}
                                options={googleStoreStatusOptions}
                                value={googleStoreStatusValue}
                                onChange={(newValue) => {
                                    methods.setFieldValue(
                                        "googleStoreStatus",
                                        newValue?.value
                                    );
                                }}
                            />
                        </Col>
                    </Form.Group>
                    : null
                }
                <EquiemOwner
                    methods={methods}
                    error={methods.errors.equiemOwner}
                />
                { showJiraUrl ?
                    <Form.Group as={Row} controlId="jiraIssueUrl">
                        <Form.Label column md="3">JIRA ticket</Form.Label>
                        <Col md="9" lg="6">
                            { jiraTicketNumber }
                            <OverlayTrigger overlay={<Tooltip id={"gotoLink"}>Go to JIRA ticket</Tooltip>}>
                                <a
                                    target="_blank"
                                    className="btn btn-link pt-0 align-top"
                                    rel="noopener noreferrer"
                                    onClick={(e) => e.stopPropagation()}
                                    href={methods.values.jiraIssueUrl}>
                                    <FaExternalLinkAlt />
                                </a>
                            </OverlayTrigger>
                        </Col>
                    </Form.Group>
                    : null
                }
            </ToggleContainer>
        );
};