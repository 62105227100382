import React, { useCallback, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import {
  SiteDetailsQuery,
  SwiftConnectSdk,
  useDisconnectSwiftconnectMutation,
} from "../../../../generated/admin";
import {
  EqDangerMessage,
  EqMessageError,
  EqMessageSuccess,
} from "../../../message/EqMessage";
import dayjs from "dayjs";
import { SwiftconnectConnectForm } from "./SwiftconnectConnectForm";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

interface ConnectInfo {
  email: string;
  timestamp: number;
  sdk?: SwiftConnectSdk | null;
}

export const SwiftconnectConnect: React.FC<Props> = ({ dest }) => {
  const [connectInfo, setConnectInfo] = useState<ConnectInfo | null>(
    dest.integrations?.swiftconnect.info != null
      ? {
          email: dest.integrations.swiftconnect.info.email,
          timestamp: dest.integrations.swiftconnect.info.connectedTimestamp,
          sdk: dest.integrations.swiftconnect.info.sdk,
        }
      : null
  );
  const [mutation, { loading }] = useDisconnectSwiftconnectMutation({
    variables: { site: dest.uuid },
  });

  const successCb = useCallback(
    (email: string, timestamp: number, sdk: SwiftConnectSdk) => {
      setConnectInfo({ email, timestamp, sdk });
    },
    []
  );

  const disconnect = useCallback(async () => {
    const confirm = await EqDangerMessage({
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Disconnect",
      title: "Are you sure?",
      html: "Once disconnected, you cannot retrieve this record.",
    });
    if (confirm.value !== true || loading) {
      return;
    }

    try {
      const result = await mutation();
      if (result.data?.swiftconnect?.disconnect == null) {
        throw new Error("Could not disconnect.");
      }

      if (!result.data.swiftconnect.disconnect.success) {
        throw new Error(
          result.data.swiftconnect.disconnect.message ??
            "Unkown error response from server."
        );
      }

      setConnectInfo(null);

      EqMessageSuccess({
        text: "Successfully disconnected from SwiftConnect.",
      });
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [mutation, loading]);

  return (
    <div className="swiftconnect pt-2">
      <h6>Connection</h6>
      <div className="border p-2 p-md-4 mb-2">
        {connectInfo == null ? (
          <SwiftconnectConnectForm dest={dest} successCb={successCb} />
        ) : (
          <div className="d-flex justify-content-between">
            <div>
              <div className="pb-2">
                <strong>Email:</strong> {connectInfo.email}
              </div>
              {connectInfo.sdk && (
                <div className="pb-2">
                  <strong>SDK type:</strong> {connectInfo.sdk.__typename}
                  {"applicationId" in connectInfo.sdk ? (
                    <>
                      <br />
                      <strong>Application ID:</strong> {connectInfo.sdk.applicationId}
                      <br />
                      <strong>HID Origo UUID:</strong> {connectInfo.sdk.hidOrigoId}
                    </>
                  ) : (
                    <>
                      <br />
                      <strong>Instance UUID:</strong> {connectInfo.sdk.instanceId}
                      <br />
                      <strong>Organization UUID:</strong> {connectInfo.sdk.organizationId}
                    </>
                  )}
                </div>
              )}
              Connected {dayjs(connectInfo.timestamp).fromNow()}
            </div>
            <Button
              name="disconnectSwiftconnect"
              variant="danger"
              size="sm"
              className="align-self-center"
              onClick={disconnect}
              disabled={loading}
            >
              {loading ? (
                <span>
                  <Spinner size="sm" animation="grow" /> Loading...
                </span>
              ) : (
                "Disconnect"
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
