import { FormikErrors } from "formik";
import {
  AppleStoreStatus,
  GoogleStoreStatus,
  UpsertWhitelabelAppInput,
  WhitelabelStatus,
} from "../../../generated/admin";
import { validEmail } from "../../../util/validEmail";
import { stringNotEmpty } from "../../../util/stringNotEmpty";
import { validURL } from "../../../util/validURL";
import { OptionType } from "../../../model/OptionType";

type BetaUser = {
  value: string;
  type: string;
};

export interface WhitelabelFormData {
  uuid?: string;
  name: string;
  appName: string;
  client: string;
  clientName: string;
  appleAccountName: string,
  appleCompanyName: string,
  googleAccountName: string,
  android: Omit<UpsertWhitelabelAppInput["androidImages"], "__typename"> & { compressedUrl?: string | null | undefined };
  ios: Omit<UpsertWhitelabelAppInput["iosImages"], "__typename"> & { compressedUrl?: string | null | undefined };
  color: string;
  subtitle?: string;
  description?: string;
  keywords?: string;
  privacyPolicyUrl?: string;
  supportUrl?: string,
  contactEmail?: string,
  releaseNotes?: string,
  language: string,
  categories: string[];
  appleKey: {
    issuerId?: string;
    keyId?: string;
    key?: string;
    id?: string;
  };
  apnsKey: {
    keyId?: string;
    key?: string;
    id?: string;
  };
  androidKey: {
    json?: string;
    id?: string;
  };
  firebaseServiceKey: {
    json?: string;
    id?: string;
  },
  betaUsers: Array<BetaUser>;
  betaUserInput: BetaUser;
  sites: string[];
  bundleId?: string;
  packageName?: string;
  appleTeamId?: string;
  appleITCTeamId?: string;
  appleAppStoreId?: string;
  applePayMerchantId?: string;
  appleCodeSigningIdentity?: string;
  editionId?: string;
  firebaseAndroidAppId?: string;
  androidFlavor?: string;
  matchGitBranch?: string;
  dynamicLinkUrl?: string;
  firebaseProjectId?: string;
  firebaseWebApiKey?: string;
  appleReviewEmail?: string;
  googleReviewEmail?: string;
  equiemOwner?: OptionType;
  appleStoreStatus?: AppleStoreStatus;
  googleStoreStatus?: GoogleStoreStatus;
  status: WhitelabelStatus;
  jiraIssueUrl?: string;
}

const hexColorRegex = /^#[0-9A-F]{6}$/i;

export const whitelabelFormValidation = (
  input: WhitelabelFormData
): FormikErrors<WhitelabelFormData> => {
  const errors: FormikErrors<WhitelabelFormData> = {};
  if (!stringNotEmpty(input.client)) {
    errors.client = "Required.";
  }
  if (!stringNotEmpty(input.name)) {
    errors.name = "Required.";
  } else if (input.name.length > 30) {
    errors.name = "Name must be 30 characters or less.";
  }
  if (stringNotEmpty(input.subtitle) && input.subtitle.length > 30) {
    errors.subtitle = "Subtitle must be 30 characters or less.";
  }
  if (stringNotEmpty(input.description) && input.description.length > 4000) {
    errors.description = "Description must be 4000 characters or less.";
  }
  if (stringNotEmpty(input.keywords) && input.keywords.length > 80) {
    errors.keywords = "Keywords must be less than 80 characters for Android.";
  }
  if (stringNotEmpty(input.privacyPolicyUrl) && !validURL(input.privacyPolicyUrl)) {
    errors.privacyPolicyUrl = "Privacy Policy must be a valid URL.";
  }
  if (stringNotEmpty(input.supportUrl) && !validURL(input.supportUrl)) {
    errors.supportUrl = "Support URL must be a valid URL.";
  }
  if (stringNotEmpty(input.contactEmail) && !validEmail(input.contactEmail)) {
    errors.contactEmail = "Contact email must be a valid email.";
  }
  if (stringNotEmpty(input.appleReviewEmail) && !validEmail(input.appleReviewEmail)) {
    errors.appleReviewEmail = "Apple Store review account must be a valid email.";
  }
  if (stringNotEmpty(input.googleReviewEmail) && !validEmail(input.googleReviewEmail)) {
    errors.googleReviewEmail = "Google Store review account must be a valid email.";
  }
  if (stringNotEmpty(input.releaseNotes) && input.releaseNotes.length > 4000) {
    errors.releaseNotes = "Release Notes must be 4000 characters or less.";
  }
  if (stringNotEmpty(input.appName) && input.appName.length > 12) {
    errors.appName = "Name must be 12 characters or less.";
  }
  if (input.equiemOwner == null) {
    errors.equiemOwner = "Required";
  }
  if (!stringNotEmpty(input.color)) {
    errors.color = "Required.";
  } else if (stringNotEmpty(input.color) && !hexColorRegex.test(input.color)) {
    errors.color =
      "The colour hex format is incorrect. A hex code must be entered in the format #000000.";
  }
  if (
    stringNotEmpty(input.appleKey.issuerId) ||
    stringNotEmpty(input.appleKey.keyId) ||
    stringNotEmpty(input.appleKey.key)
  ) {
    let errCount = 0;
    if (errors.appleKey == null) {
      errors.appleKey = {};
    }
    if (!stringNotEmpty(input.appleKey.issuerId)) {
      errors.appleKey!.issuerId = "Must supply Issuer ID when updating Key";
      errCount++;
    }
    if (!stringNotEmpty(input.appleKey.keyId)) {
      errors.appleKey!.keyId = "Must supply Key ID when updating Key";
      errCount++;
    } else if (stringNotEmpty(input.appleKey.keyId) && input.appleKey.keyId.length > 10) {
      errors.appleKey!.keyId = "Invalid Key ID";
      errCount++;
    }
    if (!stringNotEmpty(input.appleKey.key)) {
      errors.appleKey!.key = "Must supply key when updating Key";
      errCount++;
    }
    if (errCount === 0) {
      delete errors.appleKey;
    }
  } else {
    delete errors.appleKey;
  }

  if (
    stringNotEmpty(input.apnsKey.keyId) ||
    stringNotEmpty(input.apnsKey.key)
  ) {
    let errCount = 0;
    if (errors.apnsKey == null) {
      errors.apnsKey = {};
    }
    if (!stringNotEmpty(input.apnsKey.keyId)) {
      errors.apnsKey!.keyId = "Must supply Key ID when updating Key";
      errCount++;
    }
    if (!stringNotEmpty(input.apnsKey.key)) {
      errors.apnsKey!.key = "Must supply key when updating Key";
      errCount++;
    }
    if (errCount === 0) {
      delete errors.apnsKey;
    }
  } else {
    delete errors.apnsKey;
  }

  if (
    stringNotEmpty(input.firebaseServiceKey.id) ||
    stringNotEmpty(input.firebaseServiceKey.json)
  ) {
    if (!stringNotEmpty(input.firebaseProjectId)) {
      errors.firebaseProjectId = "Must supply Firebase project ID when updating Firebase service key";
    }
    if (!stringNotEmpty(input.firebaseServiceKey.id) && !stringNotEmpty(input.firebaseWebApiKey)) {
      errors.firebaseWebApiKey = "Must supply Firebase Web API Key when updating Firebase service key";
    }
  }

  return errors;
};

export const randomString = (len = 4, chars = 'abcdefghijklmnopqrstuvwxyz') => [...Array(len)].map(() => chars.charAt(Math.floor(Math.random() * chars.length))).join('');