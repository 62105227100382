import { OptionType } from "../../../../model/OptionType";
import { AdminProfileStatus, ProfileRole } from "../../../../generated/admin";

interface ProfileField {
  [key: string]: string;
}

export enum UserStatus {
  Active = "ACTIVE",
  Deactivated = "DEACTIVATED",
  PendingApproval = "PENDING_APPROVAL"
}

export interface UserFormData {
  firstName: string;
  lastName: string;
  email: string;
  status: OptionType<AdminProfileStatus>;
  activeSite?: OptionType;
  company?: OptionType;
  position?: string;
  mobile?: string;
  fields?: ProfileField;
  emailSubscription?: boolean;
  notificationSubscription?: boolean;
  avatar?: string;
  attributes?: OptionType[] | null;
  groups?: OptionType[] | null;
  sites?: string[] | string | boolean;
  siteSearchKeyword?: string;
  newWorldPermissions?: ProfileRole[] | null;
  registrationType?: OptionType;
  apartment?: OptionType;
}

export type UserFormDataKey = keyof UserFormData;

// @todo: not sure what is the best way to make sure the form name provided is
// declared in the interface attributes, since name is not type safe.
export const userFormNameCheck = (name: UserFormDataKey) => {
  return name;
};
